import React from "react";
import "./dashboard.scss";
import devNinjaText from "../../assets/dev-ninja.png";
import devNinjaLogo from "../../assets/dev-ninja-logo.png";
import { List, ListItemButton } from "@mui/material";

const homePageCardsData = {
	blogs: {
		display: "Blogs & Newsletters",
		items: [
			{ display: "ByteByteGo", href: "https://blog.bytebytego.com/" },
			{ display: "Pragmatic Notes", href: "https://pragmaticnotes.com/" },
			{
				display: "Big Technology",
				href: "https://bigtechnology.substack.com/",
			},
		],
	},
	videos: {
		display: "Learning Videos",
		items: [
			{
				display: "Web Development",
				href: "https://www.youtube.com/c/AnujSingla",
			},
			{
				display: "Data structure & Algorithms",
				href: "https://www.youtube.com/watch?v=AT14lCXuMKI&list=PLdo5W4Nhv31bbKJzrsKfMpo_grxuLl8LU",
			},
			{
				display: "Java Brains",
				href: "https://www.youtube.com/c/JavaBrainsChannel",
			},
		],
	},
	websites: {
		display: "Web Sites",
		items: [
			{ display: "tools.devninja.in", href: "https://tools.devninja.in" },
			{ display: "cfp-guru.in", href: "https://cfp-guru.in" },
			{
				display: "Technical Radar",
				href: "https://www.thoughtworks.com/en-in/radar",
			},
		],
	},
};

const Dashboard = () => {
	return (
		<>
			<div className="logo-wrapper">
				<img
					className="logo-wrapper__logo-image"
					src={devNinjaLogo}
					alt={"Dev Ninja"}
					loading="eager"
				/>
				<img
					className="logo-wrapper__logo-text"
					src={devNinjaText}
					alt={"Dev Ninja"}
					loading="eager"
				/>
			</div>
			<section className="app-section__card-container">
				{Object.entries(homePageCardsData).map((card) => (
					<div key={card[0]} className="app-section__card-container__card">
						<h2 className="app-section__card-container__card__heading">
							{card[1].display}
						</h2>
						<div className="app-section__card-container__card__list">
							<List>
								{card[1].items.map((item) => (
									<ListItemButton
										className="app-section__card-container__card__list__item"
										component="a"
										href={item.href}
										target={"_blank"}
										rel="noopener noreferrer"
									>
										{item.display}
									</ListItemButton>
								))}
							</List>
						</div>
					</div>
				))}
			</section>
		</>
	);
};

export default Dashboard;
