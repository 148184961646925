import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Dashboard from "../Dashboard/Dashboard";
import "./homepage.scss";
import devNinjaLogo from "../../assets/dev-ninja-logo.png";

const navItems = ["Home", "About", "Contact"];

export const HomePage = () => {
	return (
		<>
			<AppBar component="nav" color="transparent">
				<Toolbar>
					<Typography
						variant="h6"
						component="div"
						sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
					>
						<img
							src={devNinjaLogo}
							className="ninja-header-logo"
							alt="dev-ninja-logo"
							loading="lazy"
						/>
					</Typography>
					<Box sx={{ display: { xs: "none", sm: "block" } }}>
						{navItems.map((item) => (
							<Button key={item} sx={{ color: "#111;" }}>
								<strong className="header-links">{item}</strong>
							</Button>
						))}
					</Box>
				</Toolbar>
			</AppBar>
			<section className="app-section">
				<Dashboard />
			</section>
		</>
	);
};
